import React from "react";
import "../../App.css";
import ReactECharts from "echarts-for-react";
import SingleInfoCard from "../../components/SingleInfoCard/SingleInfoCard";
import $ from "jquery";
import axiosInstance from "../../utils/axiosInstance";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import renderChartFunction from "../../utils/helpers/renderChartFilter";
import { Tabs, Tab } from "react-bootstrap";
import FilterIcon from "../../assets/images/filter.png";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import Filter from "../../components/FilterDropdown/GenericFilter";
import withDateTime from "../../utils/timedatehoc";
import pieChart from "../../components/Charts/pieChart";
import barWithLineChart from "../../components/Charts/barWithLineChart";
import miniChart from "../../components/Charts/miniChart";
import CurrencyFormat from "react-currency-format";
import {
  filtersParams,
  mapFilters,
  handleFilterChange,
} from "../../utils/filtersHelper";
import horizontalBarChart from "../../components/Charts/horizontalBarChart";
import lineChart from "../../components/Charts/lineChart";
import {
  colorPalette,
  PASTEL_PALETTE,
  REVERSE_PASTEL,
} from "../../constants/ColorPalette";

class DigitalPaymentsDashboard extends React.Component {
  constructor(props) {
    super(props);
    var { theme } = this.props;
    this.state = {
      transactionSummaryOptionsLoading: true,
      transactionBottomCharts: [],
      transactionTabs: [],
      transactionType: "All",
      transactionSummaryOptionsData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
          {
            data: [],
          },
        ],
      },
      filters: {},
      filterData: [],
      locationFilterData: {
        text: "Location",
        data: [
          {
            label: "On Site",
            value: "On Site",
            checked: true,
            isSearched: true,
          },
          {
            label: "Off Site",
            value: "Off Site",
            checked: true,
            isSearched: true,
          },
        ],
      },

      topCashDispensedCountOptionsLoading: true,
      topCashDispensedCountOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      topExceptionsOptionsLoading: true,
      topExceptionsOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      leastCashDispensedCountOptionsLoading: true,
      leastCashDispensedCountOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      leastExceptionsOptionsLoading: true,
      leastExceptionsOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      peakTimeData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
          {
            data: [],
          },
        ],
      },
      peakTimeLoading: true,
      balancingSummaryOptionsLoading: true,
      balancingSummaryOptionsData: {
        title: "",
        data: [
          { value: 0, name: "Balanced" },
          { value: 0, name: "Unbalanced" },
        ],
      },

      matchingSummaryLoading: true,
      matchingSummaryData: {
        title: "",
        data: [
          { value: 0, name: "Matched" },
          { value: 0, name: "UnMatched" },
          { value: 0, name: "Missing" },
          { value: 0, name: "Error" },
        ],
      },
      atmProfitabilityLoading: true,
      atmProfitabilityData: {
        title: {
          text: "",
          // subtext: `${this.props?.dateTime?.currency} 10,000`,
          textStyle: {
            color: "#777",
            fontSize: "14px",
            fontFamily: "Montserrat Bold",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: "2%",
          top: "20%",
          bottom: "10%",
        },
        xAxis: [
          {
            show: false,
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            show: false,
            type: "value",
            data: ["0", "300", "600", "900", "1200", "1500", "1800"],
            splitLine: {
              lineStyle: {
                color: theme === "light" ? "#ccc" : "#333",
              },
            },
            axisLine: {
              lineStyle: {
                opacity: "0.8",
              },
            },
          },
        ],
        series: [
          {
            symbol: "circle",
            // animationDuration: 91000,
            symbolSize: 8,
            itemStyle: {
              color: "rgb(17,138,178)",
              fontFamily: "Montserrat Regular",
            },
            name: "Cash Utilization",
            type: "line",
            lineStyle: {
              color: "rgb(17,138,178)",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(17,138,178,100%)", // color at 0% position
                  },
                  {
                    offset: 0.2,
                    color: "rgba(17,138,178,80%)", // color at 0% position
                  },
                  {
                    offset: 0.4,
                    color: "rgba(17,138,178,60%)", // color at 0% position
                  },
                  {
                    offset: 0.6,
                    color: "rgba(17,138,178,40%)", // color at 0% position
                  },
                  {
                    offset: 0.8,
                    color: "rgba(17,138,178,20%)", // color at 0% position
                  },
                ],
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },

      transactionCategorizationOptionsLoading: true,
      transactionCategorizationOptionsData: {
        title: "",
        data: [],
      },

      declineTransactionCategorizationOptionsLoading: true,
      declineTransactionCategorizationOptionsData: {
        title: "",
        data: [],
      },

      transactionDestinationOptionsLoading: true,
      transactionDestinationOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      digitalKPITiles: {},
      deviceId: 0,
      gotResponse: false,
    }; // state end
  }

  getData() {
    this.setState(
      {
        atmProfitabilityLoading: true,
        matchingSummaryLoading: true,
        balancingSummaryOptionsLoading: true,
        transactionSummaryOptionsLoading: true,
        peakTimeOptionLoading: true,
        leastExceptionsOptionsLoading: true,
        leastCashDispensedCountOptionsLoading: true,
        leastCashDispensedOptionsLoding: true,
        topExceptionsOptionsLoading: true,
        topCashDispensedCountOptionsLoading: true,
        topCashDispensedOptionsLoading: true,
        transactionDestinationOptionsLoading: true,
        gotResponse: false,
        tabDefaultValue: "All",
      },
      async () => {
        try {
          let url = filtersParams(this.state.filters);
          const res = await Promise.all([
            this.getPeakTime(url),
            this.getAtmReconCharts(url),
            this.getTransactionCategorization(url),
            this.getDeclineTransactionCategorization(url),
            this.getKpiTiles(url),
            this.getTransactionSummary(
              url,
              this.state.transactionTabs.find(
                (x) => x.label === this.state.transactionType
              )?.codeValue || 240001
            ),
            this.getTransactionDestination(url),
          ]);
          if (res) {
            this.setState({
              gotResponse: true,
            });
          }
        } catch (e) {
          this.setState({
            gotResponse: true,
          });
          console.log(e);
        }
      }
    );
  }
  async getTabs() {
    try {
      const res = await axiosInstance.get(
        `/hierarchical/filters?screen_id=digital_summary_chart`
      );
      this.setState({
        transactionTabs: res.data?.filters[0]?.filterData || [],
      });
    } catch (e) {
      this.setState({ transactionTabs: [] });
      console.log(e);
    }
  }
  async getTransactionSummary(filter, type) {
    try {
      const { transactionSummaryOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/digital-summary?transaction_type=${type}&channel=digital${filter}`
      );
      const { digitalSummaryChart, bottomCharts } = response.data;
      // transaction summary chart
      transactionSummaryOptionsData.xAxis.data = [];
      transactionSummaryOptionsData.series = [
        {
          data: [],
        },
        {
          data: [],
        },
      ];
      transactionSummaryOptionsData.title = digitalSummaryChart?.header?.title;
      digitalSummaryChart?.chart?.forEach((obj) => {
        transactionSummaryOptionsData.xAxis.data.push(obj.postingDate);
        transactionSummaryOptionsData.series[1].data.push(obj.amount);
        transactionSummaryOptionsData.series[0].data.push(obj.count);
      });

      this.setState({
        transactionSummaryOptionsData,
        transactionSummaryOptionsLoading: false,
        transactionBottomCharts: bottomCharts || [],
      });
    } catch (err) {
      console.log("/api/charts/digital_summary response error ", err);
      this.setState({
        transactionSummaryOptionsLoading: false,
      });
    }
  }
  async componentDidMount() {
    $(".count-num").each(function () {
      var $this = $(this);
      $({ Counter: 0 }).animate(
        { Counter: $this.text() },
        {
          duration: 2000,
          easing: "swing",
          step: function () {
            $this.text(Math.ceil(this.Counter));
          },
        }
      );
    });
    this.getFilters();
    this.getTabs();
    this.props.dateTime.setRefresh(() => () => {
      this.getData();
    });
  }
  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async getPeakTime(url) {
    try {
      const { peakTimeData } = this.state;
      const response = await axiosInstance.get(
        `/charts/peak-times?channel=digital${url}`
      );
      const { atmPeakTimes } = response.data;
      peakTimeData.title = atmPeakTimes?.header?.title;
      peakTimeData.xAxis.data = [];
      peakTimeData.series[0].data = [];
      peakTimeData.series[1].data = [];
      atmPeakTimes?.chart?.forEach((obj) => {
        peakTimeData.xAxis.data.push(obj.time);
        peakTimeData.series[0].data.push(obj.totalCount);
        peakTimeData.series[1].data.push(obj.totalCount);
      });

      this.setState({ peakTimeData, peakTimeLoading: false });
    } catch (err) {
      console.log("/api/charts/atm_peak_times response error ", err);
    }
  }

  async getAtmReconCharts(url) {
    try {
      const { matchingSummaryData } = this.state;
      try {
        const response = await axiosInstance.get(
          `/charts/matching-summary?channel=digital${url}`
        );
        matchingSummaryData.data = [];
        matchingSummaryData.title = response.data?.header?.title;
        matchingSummaryData?.data?.push({
          name: "matched",
          value: response.data?.chart?.matchedCount || 0,
        });
        matchingSummaryData?.data?.push({
          name: "unmatched",
          value: response.data?.chart?.unmatchedCount || 0,
        });
        matchingSummaryData?.data?.push({
          name: "missing",
          value: response.data?.chart?.missingCount || 0,
        });
        matchingSummaryData?.data?.push({
          name: "error",
          value: response.data?.chart?.errorCount || 0,
        });
      } catch (error) {
        console.log("matching summary charts error", error);
      }

      this.setState({
        matchingSummaryData,
        matchingSummaryLoading: false,
      });
    } catch (err) {
      console.log("err ", err);
      this.setState({
        matchingSummaryLoading: false,
      });
    }
  }

  async getFilters() {
    try {
      const res = await axiosInstance.get(
        `/hierarchical/filters?screen_id=digital_dashboard`
      );
      // debugger;
      let obj = mapFilters(res.data.filters);
      this.setState(
        {
          filters: obj,
          filterData: res.data.filters,
          gotResponse: true,
        },
        () => {
          this.getData();
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  async getTransactionCategorization(url) {
    try {
      const { transactionCategorizationOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/transaction-categorization?channel=digital${url}`
      );

      const { transactionCategorizationCharts, header } = response?.data;

      let data = transactionCategorizationCharts?.map(
        ({ transactionType, count }) => ({
          name: transactionType,
          value: count,
        })
      );

      transactionCategorizationOptionsData.data = data;
      transactionCategorizationOptionsData.title = header?.title;

      this.setState({
        transactionCategorizationOptionsData,
        transactionCategorizationOptionsLoading: false,
      });
    } catch (err) {
      console.log("transaction categorization error", err);
      this.setState({ transactionCategorizationOptionsLoading: false });
    }
  }

  async getDeclineTransactionCategorization(url) {
    try {
      const { declineTransactionCategorizationOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/declined-transaction-categorization?channel=digital${url}`
      );

      const { declinedTransactionCategorizationCharts, header } =
        response?.data;

      let data = declinedTransactionCategorizationCharts?.map(
        ({ count, responseCode, responseDescription }) => ({
          name:
            responseDescription !== null ? responseDescription : responseCode,
          value: count,
        })
      );

      declineTransactionCategorizationOptionsData.data = data;
      declineTransactionCategorizationOptionsData.title = header?.title;

      this.setState({
        declineTransactionCategorizationOptionsData,
        declineTransactionCategorizationOptionsLoading: false,
      });
    } catch (err) {
      console.log("declined transaction categorization error", err);
      this.setState({ declineTransactionCategorizationOptionsLoading: false });
    }
  }

  async getTransactionDestination(filter) {
    try {
      const { transactionDestinationOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/transaction-destinations?channel=digital${filter}`
      );

      const { atmTransactionDestinationDto, header } = response?.data;

      // transaction destination chart
      transactionDestinationOptionsData.yAxis.data = [];
      transactionDestinationOptionsData.series.data = [];
      transactionDestinationOptionsData.title = header?.title;
      atmTransactionDestinationDto?.forEach((obj) => {
        transactionDestinationOptionsData.yAxis.data.push(obj?.destination);
        transactionDestinationOptionsData.series.data.push(obj?.count);
      });
      this.setState({
        transactionDestinationOptionsData,
        transactionDestinationOptionsLoading: false,
      });
    } catch (error) {
      console.log("transaction destination error", error);
      this.setState({ transactionDestinationOptionsLoading: false });
    }
  }

  async getKpiTiles(url) {
    try {
      const response = await axiosInstance.get(
        `/charts/digital-kpi-tiles?channel=digital${url}`
      );
      const digitalKPITiles = response?.data?.chart ?? [];
      this.setState({
        digitalKPITiles: {
          chart: digitalKPITiles,
        },
      });
    } catch (err) {
      this.setState({
        digitalKPITiles: {
          chart: [],
        },
      });
      console.log("kpi tiles response error ", err);
    }
  }

  onChartClick = {
    click: (param) => {
      renderChartFunction(
        param,
        this.props,
        this.state?.filters?.business_date?.filter[0],
        160003
      );
    },
  };

  setFilter = (filters) => {
    this.setState({ filters });
  };

  handleFilters = async () => {
    this.getData();
  };
  render() {
    const {
      peakTimeData,
      peakTimeLoading,
      matchingSummaryData,
      matchingSummaryLoading,
      transactionCategorizationOptionsData,
      transactionCategorizationOptionsLoading,
      declineTransactionCategorizationOptionsData,
      declineTransactionCategorizationOptionsLoading,
      transactionDestinationOptionsData,
      transactionDestinationOptionsLoading,
      digitalKPITiles,
    } = this.state;
    var { theme } = this.props;

    const {
      filters,
      filterData,
      transactionSummaryOptionsData,
      transactionSummaryOptionsLoading,
      transactionBottomCharts,
      transactionTabs,
    } = this.state;
    const isMobile = this.props?.dateTime?.isMobile;

    const txnBottomNoData = transactionBottomCharts?.reduce((acc, item) => {
      const data = {
        title: item?.header?.title,
        xAxis: {
          data: item?.chart?.map((obj) => obj?.postingDate) || [],
        },
        series: {
          data: item?.chart?.map((obj) => obj?.amount) || [],
        },
      };
      return {
        ...acc,
        [item?.header?.title]: !data?.series?.data?.some((d) => d !== null),
      };
    }, {});

    const matchingSummaryNoData = matchingSummaryData?.data?.every(
      (data) => data?.value == null
    );

    const txnSummaryNoData = transactionSummaryOptionsData?.series?.every(
      (series) => series?.data?.every((data) => data == null)
    );

    const txnCategorizationNoData =
      transactionCategorizationOptionsData?.data?.every(
        (data) => data?.value == null || data?.value == 0
      );

    const declineTxnCategorizationNoData =
      declineTransactionCategorizationOptionsData?.data?.every(
        (data) => data?.value == null || data?.value == 0
      );

    const txnDestinationNoData =
      transactionDestinationOptionsData?.series?.data?.every(
        (data) => data == null
      );

    const peakTimeNoData = peakTimeData?.series?.every((series) =>
      series?.data?.every((data) => data == null)
    );

    return (
      <>
        {!this.state?.gotResponse && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div style={{ opacity: this?.state?.gotResponse ? "1" : "0.07" }}>
          <div className="row align-items-center d-xs-none d-lg-flex">
            {filterData?.length > 0 && (
              <>
                {filterData?.map(
                  ({ filterName, filterType, filterLabel }, i) => (
                    <div
                      key={i}
                      className="filter-item col-sm-6 col-lg-3 mt-2"
                      style={{ maxWidth: "220px" }}
                    >
                      <Filter
                        id={filterLabel?.replace(/\s+/g, "") ?? ""}
                        title={filterLabel}
                        type={filterType}
                        data={filters[filterName]?.data}
                        filter={filters[filterName]?.filter}
                        onChange={(e) =>
                          handleFilterChange(
                            e,
                            filterName,
                            filters,
                            this.setFilter
                          )
                        }
                      />
                    </div>
                  )
                )}
              </>
            )}
            <div className="col d-flex justify-content-start">
              <ButtonBasic
                id="btn-filter"
                icon={FilterIcon}
                title="Filter"
                onClick={this.handleFilters}
              />
            </div>
          </div>
          <div className="row">
            {this.state.gotResponse &&
              digitalKPITiles &&
              digitalKPITiles?.chart &&
              digitalKPITiles?.chart?.map((tile, i) => (
                <div className="col-sm-6 col-lg-3" key={i}>
                  <SingleInfoCard
                    id={tile.title}
                    title={tile.title}
                    value={tile.totalCount}
                    per={
                      tile.trendPercentage === null
                        ? "0 %"
                        : tile.trendPercentage + "%"
                    }
                    perDown={tile.trendType === "decrease" ? true : false}
                  />
                </div>
              ))}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <SingleInfoCard id="transaction-summary">
                  <Tabs
                    defaultActiveKey="All"
                    transition={false}
                    id="Digital-dashboard"
                    className="mb-3"
                    onSelect={(e) => {
                      this.setState({
                        // transactionSummaryOptionsLoading: true,
                        transactionType: e,
                      });
                      let url = filtersParams(this.state.filters);
                      this.getTransactionSummary(
                        url,
                        transactionTabs.find((x) => x.label === e)?.codeValue
                      );
                    }}
                  >
                    {transactionTabs?.map((item) => (
                      <Tab
                        id={item?.label?.replace(/\s+/g, "-")}
                        eventKey={item?.label}
                        title={item?.label}
                        unmountOnExit
                      ></Tab>
                    ))}
                  </Tabs>
                  <div className="transaction-summary-wrapper">
                    <ReactECharts
                      className={`transaction-summary-chart${
                        txnSummaryNoData ? " no-data" : ""
                      }`}
                      onEvents={this.onChartClick}
                      option={lineChart({
                        theme: theme,
                        colorPalette: colorPalette,
                        loader: transactionSummaryOptionsLoading,
                        chartData: transactionSummaryOptionsData,
                        label: ["Count", "Amount"],
                        isMobile: isMobile,
                      })}
                      style={{
                        textTransform: "capitalize",
                        height: "300px",
                        width: "100%",
                        background: theme === "light" ? "#fff" : "#1d1d1d",
                        padding: "15px",
                        borderRadius: "17px",
                        color: "#ccc",
                      }}
                      lazyUpdate={true}
                      showLoading={transactionSummaryOptionsLoading}
                      loadingOption={{
                        text: "loading",
                        color: "#c23531",
                        textColor: "#000",
                        maskColor: `${
                          theme === "light"
                            ? "rgba(7, 6, 6, 0.1)"
                            : "rgba(7, 6, 6, 0.8)"
                        }`,
                        zlevel: 0,

                        // Font size. Available since `v4.8.0`.
                        fontSize: 12,
                        // Show an animated "spinner" or not. Available since `v4.8.0`.
                        showSpinner: true,
                        // Radius of the "spinner". Available since `v4.8.0`.
                        spinnerRadius: 10,
                        // Line width of the "spinner". Available since `v4.8.0`.
                        lineWidth: 5,
                        // Font thick weight. Available since `v5.0.1`.
                        fontWeight: "normal",
                        // Font style. Available since `v5.0.1`.
                        fontStyle: "normal",
                        // Font family. Available since `v5.0.1`.
                        fontFamily: "sans-serif",
                      }}
                      opts={{ renderer: "svg" }}
                    />
                    <div className="graph-sm-container">
                      <div className="row">
                        {transactionBottomCharts?.map((item) => {
                          const data = {
                            title: "",
                            xAxis: {
                              data: [],
                            },
                            series: {
                              data: [],
                            },
                          };
                          data.title = item?.header?.title;
                          item?.chart?.forEach((obj) => {
                            data.xAxis.data.push(obj?.postingDate);
                            data.series.data.push(obj?.amount);
                          });
                          return (
                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div
                                  id={item?.header?.title?.replace(/\s+/g, "-")}
                                  className="graph-sm-title"
                                >
                                  {item?.header?.title}
                                </div>
                                <div>
                                  <div className="graph-sm-number">
                                    <CurrencyFormat
                                      id={
                                        item?.header?.title?.replace(
                                          /\s+/g,
                                          "-"
                                        ) + "-amount"
                                      }
                                      value={item?.amount ?? 0}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`${this.props?.dateTime?.currency} `}
                                    />
                                  </div>
                                  <div
                                    id={
                                      item?.header?.title?.replace(
                                        /\s+/g,
                                        "-"
                                      ) + "-count"
                                    }
                                    className="graph-sm-number-sub"
                                  >
                                    Count {item?.count}
                                  </div>
                                </div>

                                <ReactECharts
                                  className={`"transaction-summary-mini-chart${
                                    txnBottomNoData[data?.title]
                                      ? " no-data"
                                      : ""
                                  }`}
                                  option={miniChart({
                                    chartData: data,
                                    loader: transactionSummaryOptionsLoading,
                                    label: [""],
                                  })}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  loadingOption={{
                                    text: "loading",
                                    color: "#c23531",
                                    textColor: "#000",
                                    maskColor: `${
                                      theme === "light"
                                        ? "rgba(7, 6, 6, 0.1)"
                                        : "rgba(7, 6, 6, 0.8)"
                                    }`,
                                    zlevel: 0,

                                    // Font size. Available since `v4.8.0`.
                                    fontSize: 12,
                                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                                    showSpinner: true,
                                    // Radius of the "spinner". Available since `v4.8.0`.
                                    spinnerRadius: 10,
                                    // Line width of the "spinner". Available since `v4.8.0`.
                                    lineWidth: 5,
                                    // Font thick weight. Available since `v5.0.1`.
                                    fontWeight: "normal",
                                    // Font style. Available since `v5.0.1`.
                                    fontStyle: "normal",
                                    // Font family. Available since `v5.0.1`.
                                    fontFamily: "sans-serif",
                                  }}
                                  opts={{ renderer: "svg" }}
                                  lazyUpdate={true}
                                  showLoading={transactionSummaryOptionsLoading}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </SingleInfoCard>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className={`matching-summary-pie-chart${
                    matchingSummaryNoData ? " no-data" : ""
                  }`}
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: colorPalette,
                    chartData: matchingSummaryData,
                    loader: matchingSummaryLoading,
                    label: ["matched", "unmatched", "missing", "error"],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  opts={{ renderer: "svg" }}
                  lazyUpdate={true}
                  showLoading={matchingSummaryLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                />
              </div>
            </div>

            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className={`transaction-categorization-pie-chart${
                    txnCategorizationNoData ? " no-data" : ""
                  }`}
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: REVERSE_PASTEL,
                    chartData: transactionCategorizationOptionsData,
                    loader: transactionCategorizationOptionsLoading,
                    label: [""],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={transactionCategorizationOptionsLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>

            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className={`decline-transaction-categorization-pie-chart${
                    declineTxnCategorizationNoData ? " no-data" : ""
                  }`}
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: PASTEL_PALETTE,
                    chartData: declineTransactionCategorizationOptionsData,
                    loader: declineTransactionCategorizationOptionsLoading,
                    label: [""],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={declineTransactionCategorizationOptionsLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  className={`transaction-destination-pie-chart${
                    txnDestinationNoData ? " no-data" : ""
                  }`}
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    chartData: transactionDestinationOptionsData,
                    loader: transactionDestinationOptionsLoading,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={transactionDestinationOptionsLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          {/* Peak Time */}
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <ReactECharts
                  className={`peak-time-bar-chart${
                    peakTimeNoData ? " no-data" : ""
                  }`}
                  onEvents={this.onChartClick}
                  option={barWithLineChart({
                    theme: theme,
                    loader: peakTimeLoading,
                    chartData: peakTimeData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={peakTimeLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withDateTime(DigitalPaymentsDashboard);
