import React, { useCallback, useEffect, useState } from "react";
import { Tab, TabPane, Tabs } from "react-bootstrap";
import axiosInstance from "../../../utils/axiosInstance";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../../components/pagination/pagination";
import AddUpdateAccount from "./AddUpdateAccount";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import Toast from "../../../components/Toast/Toast";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import AddIcon from "../../../assets/images/add-icon.png";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";

const AccountManagement = () => {
  const [loader, setLoader] = useState(true);
  const [accountsIndicator, setAccountsIndicator] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteAccountID, setDeleteAccountID] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 15,
    totalPages: 0,
    totalElements: 0,
  });

  const getActions = (cell, row, rowIndex) => (
    <div
      className="d-flex justify-content-start"
      onClick={(e) => e.stopPropagation()}
    >
      <i
        id={"editRow" + rowIndex}
        className="fa fa-pencil text-info px-2"
        aria-hidden="true"
        style={{ fontSize: "16px", cursor: "pointer" }}
        onClick={() => {
          setSelectedRow(row);
          setShowModal(true);
        }}
      />
      <i
        id={"deleteRow" + rowIndex}
        className="fa fa-trash text-danger px-2"
        aria-hidden="true"
        style={{ fontSize: "16px", cursor: "pointer" }}
        onClick={() => {
          setDeleteAccountID(row.id);
          setIsDeleteModal(true);
        }}
      />
    </div>
  );
  const columns = [
    {
      dataField: "id",
      text: "Recon Account Id",
      sort: true,
      formatter: (cell) => cell ?? "-",
      headerAttrs: { id: "reconIdSort" },
    },
    {
      dataField: "accountLabel",
      text: "Name",
      formatter: (cell) => cell ?? "-",
      sort: true,
      headerAttrs: { id: "reconNameSort" },
    },

    {
      dataField: "description",
      text: "Description",
      formatter: (cell) => cell ?? "-",
      style: {
        whiteSpace: "pre-wrap", // or 'pre-wrap'
        wordWrap: "break-word",
        minWidth: "341px",
      },
    },
    {
      dataField: "accountNo",
      text: "Account No",
      formatter: (cell) => cell ?? "-",
      sort: true,
      headerAttrs: { id: "accountNoSort" },
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell) => cell ?? "-",
    },
    {
      dataField: "currency",
      text: "Currency",
      formatter: (cell) => cell ?? "-",
    },
    {
      dataField: "activeInd",
      text: "Active/Inactive",
      formatter: (cell) =>
        cell !== null ? (cell === true ? "Active" : "Inactive") : "-",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: getActions,
    },
  ];
  const onSortTable = (type, newState) => {
    setPagination({
      ...pagination,
      page: 1,
    });
    setSort({
      sort_by: newState.sortField,
      sort_order: newState.sortOrder,
    });
  };
  const getAccountsIndicator = async () => {
    setLoader(true);
    axiosInstance
      .get("/accounts/account-types?typeIndicator=gl")
      .then((res) => {
        setAccountsIndicator(res?.data);
        setSelectedIndicator(res?.data[0]?.accountId);
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
        setAccountsIndicator([]);
        setSelectedIndicator(null);
        setLoader(false);
      });
  };

  const getAccounts = useCallback(
    async (page = 1, pageSize = 15, searchText = "") => {
      let sort_by;
      if (sort.sort_by === "accountLabel") sort_by = "name";
      else if (sort.sort_by === "accountNo") sort_by = "glAccountNo";
      else sort_by = sort.sort_by;

      setLoader(true);
      axiosInstance
        .get(
          `/accounts?accountTypeIds=${selectedIndicator}&page=${
            page ? page - 1 : 0
          }&limit=${pageSize ?? 15}&sort_by=${sort_by}&sort_order=${
            sort.sort_order
          }&search=${searchText ?? ""}`
        )
        .then((res) => {
          setAccounts(res?.data?.accountsList);
          setPagination({
            page: res?.page?.number + 1,
            pageSize: res?.page?.size,
            totalPages: res?.page?.totalPages,
            totalElements: res?.page?.totalElements,
          });
          setLoader(false);
        })
        .catch((e) => {
          console.log(e);
          setAccounts(null);
          setPagination({
            page: 1,
            pageSize: 15,
            totalPages: 0,
            totalElements: 0,
          });
          setLoader(false);
        });
    },
    [selectedIndicator, sort]
  );

  const deleteAccount = async () => {
    await axiosInstance
      .delete(`/accounts/${deleteAccountID}`)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          Toast(res.data, "success");
          getAccounts(1, pagination?.pageSize, searchText);
        }
      })
      .catch((err) => {
        Toast(err.response.data.message.description, "error");
      });
  };
  useEffect(() => {
    getAccountsIndicator();
  }, []);
  useEffect(() => {
    if (selectedIndicator) {
      getAccounts(1, pagination?.pageSize, searchText);
    }
  }, [getAccounts, selectedIndicator, sort]);
  return (
    <>
      {loader ? (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      ) : null}
      <div
        style={{
          opacity: loader ? "0.07" : "1",
        }}
      >
        <div className="row mt-3">
          <div className="col-md-12 page-header d-flex justify-content-between">
            <div className="title">Account Management</div>
            <div className="d-flex justify-content-end">
              <ButtonTransparent
                id="addAccount"
                onClick={() => setShowModal(true)}
                title={"Add Account"}
                icon={AddIcon}
              />
              {/* <button className="btn btn-info ml-3">Import</button>
              <button className="btn btn-info ml-3">Export</button> */}
            </div>
          </div>
          <div className="col-12">
            <div className="mt-2">
              {accountsIndicator?.length > 0 ? (
                <Tabs
                  defaultActiveKey={selectedIndicator?.accountType}
                  id="controlled-tab-example"
                  onSelect={(key) => setSelectedIndicator(key)}
                >
                  {accountsIndicator?.map((item, index) => (
                    <Tab
                      key={index}
                      eventKey={item?.accountId}
                      title={item?.accountType}
                      className="mt-3"
                    >
                      <TabPane content="Accounts List">
                        <div className="offset-lg-7 col-lg-5 offset-md-6 col-md-6 offset-sm-5 col-sm-7">
                          <div class="form-group has-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input
                              id="searchAccount"
                              type="text"
                              class="form-control"
                              placeholder="Search by Account Number or Account Name"
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  setSearchText(e.target.value);
                                  getAccounts(
                                    1,
                                    pagination?.pageSize,
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                        <BootstrapTable
                          id="accountTable"
                          bootstrap4={true}
                          bordered={false}
                          classes="rs-table table-layout-auto mb-0 table-custom-style"
                          wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                          keyField="atmId"
                          data={accounts ?? []}
                          columns={columns}
                          remote={{ pagination: true, sort: true }}
                          onTableChange={onSortTable}
                          noDataIndication="No Record Found!"
                        ></BootstrapTable>
                        <Pagination
                          setPage={(page) => {
                            getAccounts(page, pagination?.pageSize, searchText);
                          }}
                          page={pagination?.page}
                          pageSize={pagination?.pageSize}
                          setPageSize={(pageSize) => {
                            getAccounts(pagination?.page, pageSize, searchText);
                          }}
                          totalPages={pagination?.totalPages}
                          totalElements={pagination?.totalElements}
                          data={accounts || []}
                        />
                      </TabPane>
                    </Tab>
                  ))}
                </Tabs>
              ) : (
                <>
                  <div className="mb-4 ">No Account Found</div>{" "}
                  <BootstrapTable
                    id="accountTable"
                    bootstrap4={true}
                    bordered={false}
                    classes="rs-table table-layout-auto mb-0 table-custom-style"
                    wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                    keyField="atmId"
                    data={[]}
                    columns={columns}
                    noDataIndication="No Record Found!"
                  ></BootstrapTable>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <AddUpdateAccount
          showModal={showModal}
          setShowModal={setShowModal}
          selectedAccountID={selectedRow?.id}
          setSelectedAccount={setSelectedRow}
          getAccounts={getAccounts}
        />
      )}
      <ConfirmationModal
        isShow={isDeleteModal}
        title={"Delete Account"}
        message={"Are you sure you want to delete this account?"}
        hideModal={() => {
          setIsDeleteModal(false);
        }}
        confirmAction={() => {
          setIsDeleteModal(false);
          deleteAccount();
        }}
      />
    </>
  );
};

export default AccountManagement;
