import { useEffect, useState } from "react";
// import "flag-icon-css/css/flag-icon.min.css";
import notification from "../../assets/images/notification.svg";
import CheckedCircle from "../../assets/images/checked-circle.png";
import warningCirle from "../../assets/images/warning-circle.png";
import CancelCircle from "../../assets/images/cancel-circle.png";
import searchIcon from "../../assets/images/search-input-ico.png";
import syncIcon from "../../assets/images/sync.png";
import RefreshIcon from "../../assets/images/refresh.png";
import power from "../../assets/images/power.png";
import profile from "../../assets/images/person.png";
import "../../assets/styles/appStyles.scss";
import NavbarStyle from "./NavbarStyle";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
// import { constant } from "lodash-es";
// import { NAME } from "../../constants/Constants";
import logOut from "../../utils/helpers/log-out";
import axiosInstance from "../../utils/axiosInstance";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import moment from "moment";
import { useDateTime } from "../../components/Helper/DateTime";
import Toast from "../../components/Toast/Toast";
import $ from "jquery";

const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

const Navbar = ({
  showNavBar,
  toggleNavBar,
  showToggleNavBar,
  toggleTheme,
  theme,
}) => {
  const logOutUser = () => {
    logOut();
  };
  const { dateTime, systemSettings, refresh } = useDateTime();
  const [toggleSearch, setToggleSearch] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [count, setCount] = useState(0);
  const [unread, setUnread] = useState(0);
  // const minute = 60000;
  const history = useHistory();
  const userID = JSON.parse(localStorage.getItem("userData"))?.userId;
  const minute = 60000;

  // configurations for language change
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    userInfo();
    getNotifications();
    getCount();
    getNotificationsEveryMinute();
    // eslint-disable-next-line
  }, []);

  const userInfo = () => {
    axiosInstance
      .get(`/users/profile`)
      .then((response) => {
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
        } else {
          setCurrentUser(response.data.users[0]);
          const userData = localStorage.getItem("userData");
          if (userData) {
            localStorage.setItem(
              "userData",
              JSON.stringify({
                ...JSON.parse(userData),
                restrictedUser: response?.data?.users?.[0]?.restrictedUser,
              })
            );
          }
        }
      })
      .catch((error) => {
        console.log("user info error", error);
      });
  };

  const getCount = () => {
    axiosInstance
      .get(`/users/notifications/count`)
      .then((response) => {
        if (response.message.status !== "200") {
          // Toast(response.message.description, "error");
          //console.log("get count error", response.message.description);
        } else {
          setCount(response.data.count);
        }
      })
      .catch((error) => {
        console.log("get count error", error);
      });
  };

  const getNotificationsEveryMinute = () => {
    const interval = setInterval(() => {
      getNotifications();
    }, minute);

    return () => clearInterval(interval);
  };

  const getNotifications = () => {
    let unread = 0;
    axiosInstance
      .get(`/users/notifications?sort_order=desc&sort_by=dateTime`)
      .then((response) => {
        response?.data?.forEach((notification) => {
          if (notification.statusId === 1200001) {
            unread++;
          }
        });
        setUnread(unread);
        setNotifications(response.data);
      })
      .catch((error) => {
        console.log("notification error", error);
      });
  };

  const markRead = (id) => {
    axiosInstance
      .put(`/users/notifications/update-status?user_id=${userID}`, {
        data: {
          pushNotificationIds: [id],
          statusId: 1200002,
        },
      })
      .then((response) => {
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
        } else {
          setUnread(0);
          getNotifications();
        }
      })
      .catch((error) => {
        console.log("mark read error", error);
      });
  };

  const markAllAsRead = () => {
    let unreadNotif = [];
    // eslint-disable-next-line
    notifications.map((notif) => {
      if (notif.statusId === 1200001) {
        unreadNotif.push(notif.pushNotificationId);
      }
    });
    axiosInstance
      .put(`/users/notifications/update-status?user_id=${userID}`, {
        data: {
          pushNotificationIds: unreadNotif,
          statusId: 1200002,
        },
      })
      .then((response) => {
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
        } else {
          setUnread(0);
          getNotifications();
        }
      })
      .catch((error) => {
        console.log("mark all as read error", error);
      });
  };

  const getSearchResult = (query) => {
    setLoading(true);
    axiosInstance
      .get(`/recon/search?searchTerm=${query}`)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          setSearchResult(res.data.matchingData);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleSearch = (e) => {
    if (e.key === "Enter") {
      getSearchResult(query);
      if (!$("#search-result").hasClass("show")) {
        $("#search").trigger("click");
      }
    }
  };
  // TODO: add routing to screen
  const routeToScreen = (href) => {
    if (href.includes("/workqueue")) {
      history.push({
        pathname: "/workqueue",
        state: { href },
      });
    } else if (href.includes("/operations/recon-status")) {
      history.push({ pathname: "/operations", state: { href } });
    } else if (href.includes("/operations/data-collection"))
      history.push({ pathname: "/operations", state: { href } });
  };

  useEffect(() => {
    history.listen((location) => {
      if (!location.pathname.startsWith("/search")) {
        setQuery("");
        setSearchResult([]);
      }
    });
    if (history.location.pathname === "/search") {
      const searchQuery = history.location.search.split("=")[1];
      setQuery(searchQuery);
      getSearchResult(searchQuery);
    }
    // eslint-disable-next-line
  }, [history]);
  const loginTime =
    JSON.parse(localStorage.getItem("userData"))?.lastLogin || null;
  return (
    <NavbarStyle className="header">
      <div className="header-left">
        <div className="d-flex align-items-center">
          <div
            className={`ham-wrapper mr-2 d-sm-flex d-md-none`}
            onClick={() => showNavBar(true)}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </div>
          <div
            className={`ham-wrapper mr-2 d-md-flex d-xs-none cursor-pointer`}
            onClick={() => showToggleNavBar(!toggleNavBar)}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </div>
          <div className="overview"></div>
        </div>
      </div>

      <div className="header-right d-flex">
        {/* <img className="align-baseline search" src={search} alt="search" /> */}
        <div
          className={`dropdown ${
            !toggleSearch ? "d-xs-none" : "d-flex"
          } d-md-flex align-items-center`}
        >
          <input
            type="text"
            id="search"
            placeholder="Search.."
            className="search-input"
            data-toggle="dropdown"
            value={query}
            onKeyDown={(e) => handleSearch(e)}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div
            id="search-result"
            className="rs-dropdown-menu notification dropdown-menu w-lg-75"
            aria-labelledby="dropdownMenuButton"
            style={{}}
          >
            {loading && (
              <div
                className="spinner-center"
                style={{ top: "0%", left: "0%", zIndex: 2000 }}
              >
                <LoaderComponent />
              </div>
            )}
            <div
              style={{
                opacity: !loading ? "1" : "0.07",
              }}
              className="rs-general-wrapper"
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="rs-notification d-flex align-items-center justify-content-between p-2 px-4 mb-2"
              >
                <div className="rs-notification-h color-gray-dim">
                  Result: <span className="text-muted ml-2">{query}</span>
                </div>
              </div>
              {searchResult?.length > 0 ? (
                searchResult.map((item, i) => {
                  let columns = Object.keys(item.row[0].rowData);
                  return (
                    <div
                      key={i}
                      onClick={() => history.push(`/search?query=${query}`)}
                      className="m-2"
                      style={{ cursor: "pointer" }}
                    >
                      <h5 className="text text-info text-bold">{item.label}</h5>
                      <div className="overflow-y-auto fancy-scroll">
                        <table className="table">
                          <thead>
                            <tr>
                              {columns.slice(0, 3).map((column, i) => (
                                <th key={i}>
                                  {column.split("_").join(" ").toUpperCase()}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {item.row.slice(0, 3).map((data, i) => (
                              <tr key={i}>
                                {columns.slice(0, 3).map((column, i) => (
                                  <td key={i}>{data.rowData[column]}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <p style={{ fontSize: "20px", textAlign: "center" }}>
                        ...
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="notification-list">
                  <div className="notification-list-item">No Result Found</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <button className="btn refresh-btn" onClick={() => refresh()}>
          <img
            width={20}
            height={20}
            src={RefreshIcon}
            className="right-btn mr-2"
            alt="refresh"
          />
          Refresh
        </button>
        <div className="sync-date-time-wrapper d-xs-none d-lg-flex align-items-center">
          <img className="icon" src={syncIcon} alt="sync" />
          <span className="cursor-context-menu">
            {dateTime && moment(new Date()).format(dateTime)}
          </span>
        </div>

        {/* dropdown for language switch*/}
        {/* <div className="dropdown">
          <button
            className="btn btn-link dropdown-toggle mt-2"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <GlobeIcon />
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {languages.map(({ code, name, country_code }) => (
              // eslint-disable-next-line
              <a
                className={classNames("dropdown-item", {
                  disabled: currentLanguageCode === code,
                })}
                onClick={() => {
                  i18next.changeLanguage(code);
                }}
                key={country_code}
              >
                <span
                  className={`flag-icon flag-icon-${country_code} mx-2`}
                  style={{
                    opacity: currentLanguageCode === code ? 0.5 : 1,
                  }}
                ></span>
                {name}
              </a>
            ))}
          </div>
        </div> */}

        <div className="dropdown align-self-center">
          <button
            className="d-flex align-items-center notification-icon-wrapper ml-2"
            type="button"
            id="notification"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              className="align-baseline noti m-0 icon-color"
              src={notification}
              alt="noti"
            />
            {unread > 0 && (
              <span className="notification-badge" style={{ color: "white" }}>
                {unread}
              </span>
            )}
          </button>
          <div
            className="rs-dropdown-menu notification dropdown-menu fancy-scroll"
            id="notification"
            aria-labelledby="notification"
          >
            <div
              // onClick={() => console.log("Notification Click")}
              className="dropdown-item rs-notification d-flex align-items-center justify-content-between p-2 px-4 mb-2"
            >
              <span className="rs-notification-h">Notifications</span>
              {unread > 1 && (
                <span className="mark-as-read" onClick={() => markAllAsRead()}>
                  Mark All As Read
                </span>
              )}
            </div>
            {/* <div className="rs-notification-category">older</div> */}
            <div className="notification-list">
              {notifications?.length >= 1 &&
                notifications?.map((notification, i) => (
                  // eslint-disable-next-line
                  <a
                    key={i}
                    className="notification-list-item d-flex border-bottom-light dropdown-item"
                    onClick={() => {
                      markRead(notification.pushNotificationId);
                      routeToScreen(notification.href);
                    }}
                  >
                    <div className="mr-2 d-flex align-items-center">
                      <img
                        width={32}
                        height={32}
                        src={
                          notification.level === "INFO"
                            ? CheckedCircle
                            : notification.level === "WARNING"
                            ? warningCirle
                            : CancelCircle
                        }
                        alt="noti-icon"
                      />
                    </div>
                    <div>
                      <div className="notification-time d-flex justify-content-between align-items-center">
                        {notification.dateTime
                          ? moment(notification.dateTime).format(dateTime)
                          : ""}
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          style={{
                            whiteSpace: "break-spaces",
                            marginRight: "14px",
                          }}
                        >
                          {notification.content}
                        </span>
                        {notification.statusId === 1200001 && (
                          <span className="notification-dot"></span>
                        )}
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          </div>
        </div>

        <div
          className="notification-icon-wrapper d-flex d-md-none align-items-center"
          onClick={() => setToggleSearch(!toggleSearch)}
        >
          <img
            className="align-baseline noti m-0 icon-color"
            src={searchIcon}
            alt="searchIcon"
            width="18"
            height="18"
          />
        </div>
        <div className="d-flex align-items-center user-card-menu">
          <div id="userDropDown" className="dropdown">
            <div className="d-flex flex-row" data-toggle="dropdown">
              {currentUser.firstName && (
                <div className="user-dropdown-content">
                  <div className="mr-2 user-name">
                    {`${currentUser.firstName} ${currentUser.lastName}`}
                  </div>
                  <div className="mr-2 user-title">
                    {currentUser.roles[0].name}
                  </div>
                </div>
              )}
              {currentUser.firstName && (
                <div
                  className="align-baseline cursor-pointer"
                  style={{
                    background: "#118AB2",
                    width: "50px",
                    height: "50px",
                    fontSize: "25px",
                    borderRadius: "50%",
                    textAlign: "center",
                    paddingTop: "6px",
                    color: "#fff",
                  }}
                >
                  <span className="">{`${
                    currentUser?.firstName && currentUser?.firstName[0]
                  }${
                    currentUser.lastName ? currentUser?.lastName[0] : ""
                  }`}</span>
                </div>
              )}
            </div>

            <div
              className="rs-dropdown-menu dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            >
              <form>
                <div className="d-flex rs-dropdown-item dark-mode dropdown-item">
                  <ToggleSwitch
                    theme={theme}
                    toggleTheme={toggleTheme}
                    title="Dark Mode"
                  />
                </div>
                {currentUser?.branches && currentUser?.branches?.length > 0 && (
                  <div className="rs-dropdown-item dropdown-item mt-3 text-wrap">
                    Branch: {currentUser?.branches?.join(", ")}
                  </div>
                )}
                <div
                  className={`rs-dropdown-item dropdown-item${
                    currentUser?.branches?.length > 0 ? "" : " mt-3"
                  }`}
                >
                  Last login:{" "}
                  {loginTime ? moment(loginTime).format(dateTime) : ""}
                </div>
                <div className="rs-dropdown-item rs-service-status dropdown-item">
                  Service Status:{" "}
                  <span className="rs-color-circle success"></span>
                </div>
                <div className="rs-dropdown-item rs-service-status dropdown-item pb-4">
                  Release No: {systemSettings?.currentReleaseNumber}
                </div>

                <div
                  onClick={() => {
                    history.push("/profile");
                    //hide dropdown
                    document.getElementsByTagName("body")[0].click();
                  }}
                  className="rs-dropdown-item logout dropdown-item pb-3 d-flex align-items-center"
                >
                  <img
                    width={"18"}
                    height={"18"}
                    src={profile}
                    alt={"profile"}
                  />
                  <span className="ml-2" style={{ paddingTop: "4px" }}>
                    Profile
                  </span>
                </div>
                <div
                  onClick={logOutUser}
                  className="rs-dropdown-item logout dropdown-item"
                >
                  <img width={"18"} height={"18"} src={power} alt={"power"} />
                  <span className="ml-2">Logout</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </NavbarStyle>
  );
};

export default Navbar;
