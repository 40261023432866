import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import axiosInstance from "../../utils/axiosInstance";
import { WorkQueueLogStyle } from "./Styles";
import CurrencyFormat from "react-currency-format";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import Toast from "../../components/Toast/Toast";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import buttonChecker from "../../utils/buttonsPermissionsChecker";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import { useDateTime } from "../../components/Helper/DateTime";
import { CustomTooltip } from "../../utils/helpers/custom-tooltip";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { DateFormat } from "../../utils/formatDateTime";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination from "../../components/pagination/pagination";
import { Modal } from "react-bootstrap";

const FormSchema = Yup.object().shape({
  comment: Yup.string().min(2, "Too Short!").required("Required*"),
});

const WorkQueueModal = ({
  id,
  showModal,
  handleCloseModal,
  fetchWorkQueue,
}) => {
  const { date, currency } = useDateTime();
  const [workQueueData, setWorkQueueData] = useState(null);
  const [isNote, setIsNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adjustmentEntries, setAdjustmentEntries] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [showNotes, setShowNotes] = useState(false);
  const [modalPagination, setModalPagination] = useState({
    adjustmentPagination: {
      pageStartIndex: 1,
      nextPageText: ">",
      prePageText: "<",
      hideSizePerPage: true,
      showTotal: false,
      paginationShowsTotal: false,
      sizePerPage: 10,
      page: 1,
    },
    dsAPagination: {
      pageStartIndex: 1,
      nextPageText: ">",
      prePageText: "<",
      hideSizePerPage: true,
      showTotal: false,
      paginationShowsTotal: false,
      sizePerPage: 10,
      page: 1,
    },
    dsBPagination: {
      pageStartIndex: 1,
      nextPageText: ">",
      prePageText: "<",
      hideSizePerPage: true,
      showTotal: false,
      paginationShowsTotal: false,
      sizePerPage: 10,
      page: 1,
    },
  });
  const getWorkQueue = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/work-queue/work-item?itemId=${id}`);
      if (res.message.status !== "200") {
        Toast(res.message.description, "error");
      } else {
        setWorkQueueData(res?.data);
        if (res?.data?.customData) {
          setAdjustmentEntries(JSON.parse(res?.data?.customData));
        } else if (
          res?.data?.hasOwnProperty("balancingData") &&
          res?.data?.balancingData[0]?.customData
        ) {
          setAdjustmentEntries(
            JSON.parse(res?.data?.balancingData[0]?.customData)
          );
        }
        setLoading(false);
      }
    } catch (err) {
      console.log("error", err);
      setLoading(false);
      Toast(err.response.data.message.description, "error");
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getWorkQueue();
    }
  }, [getWorkQueue, id]);

  const addNote = (values) => {
    let now = moment();
    let note = {
      comment: values?.comment,
      author: `${userData?.firstName} ${userData?.lastName}`,
      date: now.format("DD/MM/YY"),
      time: now.hour() + ":" + now.minute(),
    };
    // setComment("");
    let notes = workQueueData?.notes || [];
    notes.push(note);
    setWorkQueueData({ ...workQueueData, notes: notes });
  };
  const handleDone = async (value) => {
    try {
      setLoading(true);

      if (isNote) {
        const response = await axiosInstance.put("/work-queue/work-item", {
          workItemId: id,
          action: value,
          notes: workQueueData?.notes,
          userId: userData?.userId,
        });

        const { status, description } = response.message;

        if (status === "200") {
          handleCloseModal();
          fetchWorkQueue();
          setWorkQueueData(null);
          setAdjustmentEntries(null);
          setIsNote(false);
          setShowNotes(false);
        } else {
          Toast(description, "error");
        }
      } else {
        Toast("Please add a note", "error");
        //In Modal Move to Top for Showing Notes Section
        document
          .getElementsByClassName("modal")?.[0]
          .scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    } catch (err) {
      console.error(err);
      Toast(err.response?.data?.message?.description, "error");
    } finally {
      setLoading(false);
    }
  };

  const reconStatus = (cell) =>
    cell ? (
      <BadgeRounded
        color={cell.toLowerCase() === "balanced" ? "success" : "danger"}
        title={cell}
      />
    ) : (
      "-"
    );
  const formatNumber = (number) => {
    if (number === null) {
      return; // Return nothing if number is null
    }
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };
  const formatAmount = (cell) => {
    const isNegative = cell < 0;
    const formattedValue = isNegative ? `${Math.abs(cell)}` : `${cell}`;

    return (
      <>
        <div className="d-flex justify-content-end">
          <span className="item px-3">{currency}</span>
          <span className="item">
            <CurrencyFormat
              value={Math.abs(cell)}
              displayType={"text"}
              thousandSeparator={true}
              renderText={() => {
                return <span>{formatNumber(formattedValue)}</span>;
              }}
            />
          </span>
        </div>
      </>
    );
  };

  const adjustmentEntriesColumns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "transactionDetails",
      text: "Transaction Details",
      formatter: (cell, row, rowIndex) => (
        <div>
          <BootstrapTable
            bordered={false}
            keyField="id"
            wrapperClasses="overflow-y-hidden overflow-x-hidden"
            classes="rs-table table-layout-auto mb-0 table-custom-style"
            columns={[
              {
                dataField: "glAccount",
                text: "GL Account",
                style: { minWidth: "100px" },
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "postingDate",
                text: "Posting Date",
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "description",
                text: "Description",
                style: {
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  minWidth: "342px",
                  lineHeight: "1.5",
                },
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "creditAmount",
                text: "Credit Amount",
                formatter: (cell, row) => (cell ? formatNumber(cell) : "-"),
              },
              {
                dataField: "debitAmount",
                text: "Debit Amount",
                formatter: (cell, row) => (cell ? formatNumber(cell) : "-"),
              },
              {
                dataField: "entryReference",
                text: "Entry Reference",
                formatter: (cell) => (cell ? cell : "-"),
              },
            ]}
            data={cell || []}
            headerClasses={rowIndex === 0 ? null : "visibility-collapse"}
          />
          {adjustmentEntries?.length - 1 !== rowIndex && <hr />}
        </div>
      ),
    },
  ];

  const columnsBalancingData = [
    { hidden: true, dataField: "terminalId", text: "terminalId" },
    {
      dataField: "terminalId",
      dataAlign: "left",
      text: "Terminal ID",
      sort: true,
    },
    {
      dataField: "deviceName",
      dataAlign: "left",
      text: "Device Name",
      sort: true,
    },
    {
      dataField: "reconStatus",
      dataAlign: "center",
      formatter: reconStatus,
      text: "Recon Status",
      sort: true,
    },
    {
      dataField: "adjustmentAmount",
      text: "Adjustment Amount",
      formatter: formatAmount,
      sort: true,
      headerAlign: "right",
    },
  ];

  const columnsSuspiciousData = [
    { hidden: true, dataField: "id", text: "Device ID" },

    {
      dataField: "reconAccount",
      dataAlign: "left",
      text: "Device ID",
      sort: true,
    },
    {
      dataField: "postingDate",
      dataAlign: "left",
      text: "Posting Date",
      formatter: (cell) => DateFormat(date, cell),
      sort: true,
    },
    {
      dataField: "stan",
      text: "STAN",
      formatter: (cell) => cell ?? "-",
    },

    {
      dataField: "responseCode",
      text: "Response Code",
      formatter: (cell) => cell ?? "-",
    },
    {
      dataField: "adjustmentAmount",
      text: "Adjustment Amount",
      formatter: formatAmount,
      sort: true,
      headerAlign: "right",
    },
    {
      dataField: "rrn",
      text: "RRN",
      formatter: (cell) => cell ?? "-",
    },
    {
      dataField: "reconStatus",
      dataAlign: "center",
      formatter: reconStatus,
      text: "Recon Status",
      sort: true,
    },
  ];

  const reconcileStatus = (status) =>
    status && (
      <BadgeRounded
        color={
          status === "MATCHED"
            ? "success"
            : status === "MISSING"
            ? "warning"
            : "danger"
        }
        title={status}
      />
    );

  return (
    <Modal
      backdrop={true}
      size="xl"
      show={showModal}
      onHide={() => {
        handleCloseModal();
        setWorkQueueData(null);
        setAdjustmentEntries(null);
        setIsNote(false);
        setShowNotes(false);
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="pb-0" closeButton></Modal.Header>
      <Modal.Body scrollable="true">
        <WorkQueueLogStyle>
          {loading && (
            <div
              className="spinner-center"
              style={{ top: "0%", left: "0%", zIndex: 9999 }}
            >
              <LoaderComponent />
            </div>
          )}
          <div
            style={{
              opacity: !loading ? "1" : "0.07",
            }}
          >
            <div className="row">
              <div className={showNotes ? "col-8" : "col-12"}>
                <div className="row">
                  <div className="col-sm-12 col-lg-12">
                    <span className="color-gray-dim">Queue Item:</span> {id}
                  </div>
                  {workQueueData?.payload?.manualMatchTransaction[0]
                    ?.matching_flow === "Bind-Located-Transaction" ? null : (
                    <>
                      <div className="col-sm-12 col-lg-6">
                        <div className="key-value-wrapper info-tile">
                          <div className="key color-light">
                            Adjustment Category
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <div className="key-value-wrapper info-tile">
                          <div className="value color-dark">
                            {workQueueData?.adjustmentCategoryName || "Nill"}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <div className="key-value-wrapper info-tile">
                          <div className="key color-light">Debit Account</div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <div className="key-value-wrapper info-tile">
                          <div className="value color-dark">
                            {workQueueData?.accountDetails?.debitAccount ||
                              "Nill"}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <div className="key-value-wrapper info-tile">
                          <div className="key color-light">Credit Account</div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <div className="key-value-wrapper info-tile">
                          <div className="value color-dark">
                            {workQueueData?.accountDetails?.creditAccount ||
                              "Nill"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-sm-12 col-lg-6">
                    <div className="key-value-wrapper info-tile">
                      <div className="key color-light">Raised By</div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-lg-6">
                    <div className="key-value-wrapper info-tile">
                      <div className="value color-dark">
                        {workQueueData?.raisedBy || "Nill"}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="key-value-wrapper info-tile">
                      <div className="key color-light">Authoriser</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="key-value-wrapper info-tile">
                      <div className="value color-dark">
                        {workQueueData?.authorizer || "Nill"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showNotes && (
                <div className="col-4">
                  <div className="notes-wrapper">
                    <h5 className="mb-3 color-gray-dim">Notes</h5>
                    <div
                      className="overflow-auto fancy-scroll"
                      style={{ height: "15em" }}
                    >
                      {workQueueData?.notes?.map(
                        ({ author, comment, date, time }, i) => (
                          <ul className="rs-notes-container" key={i}>
                            <li className="rs-notes-item">
                              <h6>{author}</h6>
                              <div className="note-comment">{comment}</div>
                              <sub>
                                {date} {time}
                              </sub>
                            </li>
                          </ul>
                        )
                      )}
                    </div>

                    <Formik
                      initialValues={{
                        comment: "",
                      }}
                      validationSchema={FormSchema}
                      onSubmit={(values, { resetForm }) => {
                        addNote(values);
                        setIsNote(true);
                        resetForm({ values: "" });
                      }}
                    >
                      {({ errors, touched, handleSubmit, handleReset }) => (
                        <Form
                          id="add-notes"
                          className="form"
                          onSubmit={handleSubmit}
                          onReset={handleReset}
                        >
                          <div className="row mt-3">
                            <div className="col-12 mt-3">
                              <div className="d-flex flex-column">
                                <Field
                                  className="rs-input"
                                  type="textarea"
                                  placeholder="Write a Note"
                                  rows="3"
                                  as="textarea"
                                  name="comment"
                                />
                                {errors.comment && touched.comment ? (
                                  <div className="field-error">
                                    {errors.comment}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="w-100 mt-2">
                            <ButtonBasic
                              type="submit"
                              classes={"primary px-4 w-100"}
                              title={"Add Note"}
                              onClick={handleSubmit}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
            {!(workQueueData?.matching_flow === "Nostro-Manual-Matching") ? (
              workQueueData?.payload?.manualMatchTransaction[0]
                ?.matching_flow === "Bind-Located-Transaction" ? (
                <div className="row  overflow-auto fancy-scroll">
                  <div className="row">
                    <div className="text-info pl-4 pt-3">
                      {workQueueData?.payload?.dsA}
                    </div>
                  </div>
                  <div className="col-12">
                    <table className={` table`}>
                      <thead>
                        <tr>
                          {workQueueData &&
                            workQueueData?.payload?.manualMatchTransaction[0]?.headers?.map(
                              (label, itemIndex) => (
                                <th
                                  key={itemIndex}
                                  className={`text-info ${
                                    label === "Balance" ||
                                    label === "Debit" ||
                                    label === "Credit" ||
                                    label === "Amount"
                                      ? "text-right"
                                      : ""
                                  }`}
                                >
                                  {label
                                    ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </th>
                              )
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {workQueueData?.payload?.manualMatchTransaction[0]?.matchingData[0]?.map(
                          (item) => {
                            return (
                              <tr>
                                {workQueueData?.payload?.manualMatchTransaction[0]?.headers?.map(
                                  (label) =>
                                    label === "Balance" ||
                                    label === "Debit" ||
                                    label === "Credit" ||
                                    label === "Amount" ? (
                                      <td key={label} className="text-right">
                                        {item[label]
                                          ? formatNumber(item[label])
                                          : null}
                                      </td>
                                    ) : (
                                      <td
                                        key={label}
                                        style={
                                          label === "Description"
                                            ? {
                                                whiteSpace: "normal", // or 'pre-wrap'
                                                wordWrap: "break-word",
                                                minWidth: "322px",
                                              }
                                            : label === "status"
                                            ? {
                                                minWidth: "120px",
                                                width: "120px",
                                              }
                                            : null
                                        }
                                      >
                                        {item[label]
                                          ? label === "status"
                                            ? reconcileStatus(item[label])
                                            : label === "Posting Date"
                                            ? DateFormat(date, item[label])
                                            : item[label]
                                          : ""}
                                      </td>
                                    )
                                )}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="text-danger pl-4 pt-3">
                      {workQueueData?.payload?.dsB}
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <table className={`table`}>
                      <thead>
                        <tr>
                          {workQueueData &&
                            workQueueData?.payload?.manualMatchTransaction[0]?.headers?.map(
                              (label, itemIndex) => (
                                <th
                                  key={itemIndex}
                                  className={`text-info ${
                                    label === "Balance" ||
                                    label === "Debit" ||
                                    label === "Credit" ||
                                    label === "Amount"
                                      ? "text-right"
                                      : ""
                                  }`}
                                >
                                  {label
                                    ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </th>
                              )
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {workQueueData?.payload?.manualMatchTransaction[0]?.matchingData[1]?.map(
                          (item) => {
                            return (
                              <tr>
                                {" "}
                                {workQueueData?.payload?.manualMatchTransaction[0]?.headers?.map(
                                  (label) =>
                                    label === "Balance" ||
                                    label === "Debit" ||
                                    label === "Credit" ||
                                    label === "Amount" ? (
                                      <td key={label} className="text-right">
                                        {item[label]
                                          ? formatNumber(item[label])
                                          : null}
                                      </td>
                                    ) : (
                                      <td
                                        key={label}
                                        style={
                                          label === "Description"
                                            ? {
                                                whiteSpace: "normal", // or 'pre-wrap'
                                                wordWrap: "break-word",
                                                minWidth: "322px",
                                              }
                                            : label === "status"
                                            ? {
                                                minWidth: "120px",
                                                width: "120px",
                                              }
                                            : null
                                        }
                                      >
                                        {item[label]
                                          ? label === "status"
                                            ? reconcileStatus(item[label])
                                            : label === "Posting Date"
                                            ? DateFormat(date, item[label])
                                            : item[label]
                                          : ""}
                                      </td>
                                    )
                                )}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="row  overflow-auto fancy-scroll">
                  <div className="row">
                    <div className="text-info pl-4 pt-3">
                      {workQueueData?.custom_field_1}
                    </div>
                  </div>
                  <div className="col-12">
                    <table
                      className={`${
                        workQueueData?.dsA === workQueueData?.selected_ds_name
                          ? "matching-box-shadow"
                          : ""
                      } table`}
                    >
                      {workQueueData &&
                        workQueueData?.manualMatchTransactions?.length > 0 && (
                          <thead>
                            <tr>
                              {workQueueData?.manualMatchTransactions[0].matchingData.map(
                                (item, itemIndex) => (
                                  <th
                                    key={itemIndex}
                                    style={{ textAlign: "center" }}
                                  >
                                    {item.label}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                        )}

                      <tbody>
                        {workQueueData &&
                          workQueueData?.manualMatchTransactions?.map(
                            (transaction, index) =>
                              index >=
                                (modalPagination?.dsAPagination?.page - 1) *
                                  modalPagination?.dsAPagination?.sizePerPage &&
                              index <
                                modalPagination?.dsAPagination?.page *
                                  modalPagination?.dsAPagination
                                    ?.sizePerPage && (
                                <tr key={index}>
                                  {transaction?.matchingData?.map(
                                    (item, itemIndex) => (
                                      <td
                                        key={itemIndex}
                                        style={{ textAlign: "center" }}
                                      >
                                        {item.label === "AMOUNT"
                                          ? formatNumber(
                                              item.datasourceA["value"]
                                            ) ?? "-"
                                          : item.label === "POSTING DATE"
                                          ? DateFormat(
                                              date,
                                              item.datasourceA["value"]
                                            )
                                          : item.datasourceA["value"] ?? "-"}
                                      </td>
                                    )
                                  )}
                                </tr>
                              )
                          )}
                      </tbody>
                    </table>
                    {workQueueData?.manualMatchTransactions?.length > 10 && (
                      <div>
                        <Pagination
                          totalSize={
                            workQueueData?.manualMatchTransactions?.length
                          }
                          page={modalPagination?.dsAPagination?.page}
                          sizePerPage={
                            modalPagination?.dsAPagination?.sizePerPage
                          }
                          setPage={(page) =>
                            setModalPagination({
                              ...modalPagination,
                              dsAPagination: {
                                ...modalPagination?.dsAPagination,
                                page,
                              },
                            })
                          }
                          totalPages={
                            workQueueData?.manualMatchTransactions?.length /
                            modalPagination?.dsAPagination?.sizePerPage
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="text-success pl-4 pt-3">
                      {workQueueData?.custom_field_2}
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <table
                      className={`${
                        workQueueData?.dsB === workQueueData?.selected_ds_name
                          ? "matching-box-shadow"
                          : ""
                      } table`}
                    >
                      {workQueueData &&
                        workQueueData?.manualMatchTransactions?.length > 0 && (
                          <thead>
                            <tr>
                              {workQueueData?.manualMatchTransactions[0].matchingData.map(
                                (item, itemIndex) => (
                                  <th
                                    key={itemIndex}
                                    style={{ textAlign: "center" }}
                                  >
                                    {item.label}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                        )}
                      <tbody>
                        {workQueueData &&
                          workQueueData?.manualMatchTransactions?.map(
                            (transaction, index) =>
                              index >=
                                (modalPagination?.dsBPagination?.page - 1) *
                                  modalPagination?.dsBPagination?.sizePerPage &&
                              index <
                                modalPagination?.dsBPagination?.page *
                                  modalPagination?.dsBPagination
                                    ?.sizePerPage && (
                                <tr key={index} style={{ textAlign: "center" }}>
                                  {transaction.matchingData.map(
                                    (item, itemIndex) => (
                                      <td key={itemIndex}>
                                        {item.label === "AMOUNT"
                                          ? formatNumber(
                                              item.datasourceB["value"]
                                            ) ?? "-"
                                          : item.label === "POSTING DATE"
                                          ? DateFormat(
                                              date,
                                              item.datasourceB["value"]
                                            )
                                          : item.datasourceB["value"] ?? "-"}
                                      </td>
                                    )
                                  )}
                                </tr>
                              )
                          )}
                      </tbody>
                    </table>
                    {workQueueData?.manualMatchTransactions?.length > 10 && (
                      <div>
                        <Pagination
                          totalSize={
                            workQueueData?.manualMatchTransactions?.length
                          }
                          page={modalPagination?.dsBPagination?.page}
                          sizePerPage={
                            modalPagination?.dsBPagination?.sizePerPage
                          }
                          setPage={(page) =>
                            setModalPagination({
                              ...modalPagination,
                              dsBPagination: {
                                ...modalPagination?.dsBPagination,
                                page,
                              },
                            })
                          }
                          totalPages={
                            workQueueData?.manualMatchTransactions?.length /
                            modalPagination?.dsBPagination?.sizePerPage
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )
            ) : (
              <>
                <div className="row overflow-auto fancy-scroll">
                  <div className="row mt-1">
                    <div className="color-gray-dim pl-5 text-info">
                      {workQueueData?.custom_field_1}
                    </div>
                  </div>
                  <div className="col-12">
                    <table
                      className={`table table-dim-dark table-bordered ${
                        workQueueData?.dsA === workQueueData?.selected_ds_name
                          ? "matching-box-shadow"
                          : ""
                      }`}
                    >
                      <thead>
                        <tr>
                          {Object.keys(
                            workQueueData?.manualMatchTransactions[0]
                              ?.matchingData[0]?.[0] ??
                              workQueueData?.manualMatchTransactions[0]
                                ?.matchingData[1]?.[0]
                          ).map((key) => (
                            <th
                              key={key}
                              className={`text-info ${
                                key === "Balance" ||
                                key === "Debit" ||
                                key === "Credit" ||
                                key === "Amount"
                                  ? "text-right"
                                  : ""
                              }`}
                            >
                              {key
                                .replace(/([a-z])([A-Z])/g, "$1 $2")
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {workQueueData?.manualMatchTransactions?.map((Item) =>
                          Item?.matchingData[0]?.map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                              }}
                            >
                              {Object?.keys(item)?.map((key, i) =>
                                item[key] && key !== "status" ? (
                                  <>
                                    {
                                      <td
                                        style={
                                          key === "Description"
                                            ? {
                                                whiteSpace: "normal", // or 'pre-wrap'
                                                wordWrap: "break-word",
                                                minWidth: "341px",
                                              }
                                            : null
                                        }
                                        className={
                                          key === "Debit" ||
                                          key === "Credit" ||
                                          key === "Balance" ||
                                          key === "Amount"
                                            ? "text-right"
                                            : ""
                                        }
                                      >
                                        {item[key]
                                          ? key === "Posting Date"
                                            ? DateFormat(date, item[key])
                                            : item[key]
                                          : "-"}
                                      </td>
                                    }
                                  </>
                                ) : key === "status" ? (
                                  <td
                                    style={{
                                      minWidth: "120px",
                                      width: "120px",
                                    }}
                                  >
                                    {reconcileStatus(item[key])}
                                  </td>
                                ) : null
                              )}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row mt-1">
                    <div className="color-gray-dim pl-5 text-danger">
                      {workQueueData?.custom_field_2}
                    </div>
                  </div>
                  <div className="col-12">
                    <table
                      className={`table table-dim-dark table-bordered ${
                        workQueueData?.dsB === workQueueData?.selected_ds_name
                          ? "matching-box-shadow"
                          : ""
                      }`}
                    >
                      <thead>
                        <tr>
                          {Object.keys(
                            workQueueData?.manualMatchTransactions[0]
                              ?.matchingData[1]?.[0] ??
                              workQueueData?.manualMatchTransactions[0]
                                ?.matchingData[0]?.[0]
                          ).map((key) => (
                            <th
                              className={`text-info ${
                                key === "Balance" ||
                                key === "Debit" ||
                                key === "Credit" ||
                                key === "Amount"
                                  ? "text-right"
                                  : ""
                              }`}
                              key={key}
                            >
                              {key
                                .replace(/([a-z])([A-Z])/g, "$1 $2")
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {workQueueData?.manualMatchTransactions?.map((Item) =>
                          Item?.matchingData[1]?.map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                              }}
                            >
                              {Object?.keys(item)?.map((key, i) =>
                                item[key] && key !== "status" ? (
                                  <>
                                    {
                                      <td
                                        style={
                                          key === "Description"
                                            ? {
                                                whiteSpace: "normal", // or 'pre-wrap'
                                                wordWrap: "break-word",
                                                minWidth: "341px",
                                              }
                                            : null
                                        }
                                        className={
                                          key === "Debit" ||
                                          key === "Credit" ||
                                          key === "Balance" ||
                                          key === "Amount"
                                            ? "text-right"
                                            : ""
                                        }
                                      >
                                        {item[key]
                                          ? key === "Posting Date"
                                            ? DateFormat(date, item[key])
                                            : item[key]
                                          : "-"}
                                      </td>
                                    }
                                  </>
                                ) : key === "status" ? (
                                  <td
                                    style={{
                                      minWidth: "120px",
                                      width: "120px",
                                    }}
                                  >
                                    {reconcileStatus(item[key])}
                                  </td>
                                ) : null
                              )}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            <div className="row">
              <div className="col-12">
                {workQueueData?.isATMBalancing && (
                  <BootstrapTable
                    columns={
                      workQueueData?.balancingType === 1
                        ? columnsBalancingData
                        : columnsSuspiciousData
                    }
                    bootstrap4={true}
                    keyField="terminalId"
                    bordered={false}
                    classes="rs-table table-layout-auto table-custom-style"
                    wrapperClasses="overflow-y-auto fancy-scroll"
                    data={workQueueData?.balancingData}
                    noDataIndication={"No data found!"}
                  />
                )}
              </div>
            </div>
            {adjustmentEntries && (
              <div className="row mt-4 w-100">
                <div className="col-12">
                  <div className="mt-4 color-success mb-2">
                    Manual Adjustment Entries
                  </div>
                  <BootstrapTable
                    keyField="id"
                    data={adjustmentEntries}
                    columns={adjustmentEntriesColumns}
                    bordered={false}
                    classes="rs-table table-layout-auto table-custom-style"
                    headerClasses="d-none"
                    noDataIndication="No data found!"
                    wrapperClasses="overflow-y-auto fancy-scroll"
                    pagination={
                      adjustmentEntries?.length > 10
                        ? paginationFactory(
                            modalPagination?.adjustmentPagination
                          )
                        : false
                    }
                  ></BootstrapTable>
                </div>
              </div>
            )}
            <div
              className="row"
              style={{
                opacity: !loading ? "1" : "0.07",
              }}
            >
              <div className="col-sm-5 mt-3">
                {workQueueData?.workItemStatus === "APPROVED" ||
                workQueueData?.workItemStatus === "DECLINED" ? (
                  ""
                ) : (
                  <div className="alert alert-danger" role="alert">
                    This work item requires approval.
                  </div>
                )}
              </div>
              <div
                className="offset-sm-3 col-sm-4 text-right mt-3 d-flex"
                style={{ justifyContent: "space-evenly" }}
              >
                <ButtonBasic
                  title={showNotes ? "Hide Notes" : "Show Notes"}
                  onClick={() => {
                    setShowNotes(!showNotes);
                    //In Modal Move to Top for Showing Notes Section
                    document
                      .getElementsByClassName("modal")?.[0]
                      .scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                />
                <CustomTooltip
                  placement="top"
                  tooltipId="button-tooltip-2"
                  className="ml-2"
                  disabled={
                    !buttonChecker("clk_authorise_work_item") ||
                    workQueueData?.workItemStatus === "APPROVED" ||
                    workQueueData?.workItemStatus === "DECLINED" ||
                    workQueueData?.raisedById === userData?.userId
                  }
                  disabledMessage={
                    workQueueData?.workItemStatus === "APPROVED"
                      ? "Already Approved"
                      : workQueueData?.workItemStatus === "DECLINED"
                      ? "Already Declined"
                      : workQueueData?.raisedById === userData?.userId
                      ? "You are not allowed to approve or reject the work item"
                      : "Permission Denied"
                  }
                  onClick={() => handleDone(0)}
                  buttonText="Reject"
                >
                  Reject
                </CustomTooltip>{" "}
                <CustomTooltip
                  placement="top"
                  tooltipId="button-tooltip-2"
                  className="ml-2"
                  disabled={
                    !buttonChecker("clk_authorise_work_item") ||
                    workQueueData?.workItemStatus === "APPROVED" ||
                    workQueueData?.workItemStatus === "DECLINED" ||
                    workQueueData?.raisedById === userData?.userId
                  }
                  disabledMessage={
                    workQueueData?.workItemStatus === "APPROVED"
                      ? "Already Approved"
                      : workQueueData?.workItemStatus === "DECLINED"
                      ? "Already Declined"
                      : workQueueData?.raisedById === userData?.userId
                      ? "You are not allowed to approve or reject the work item"
                      : "Permission Denied"
                  }
                  onClick={() => handleDone(1)}
                  buttonText="Approve"
                >
                  Approve
                </CustomTooltip>
              </div>
            </div>
          </div>
        </WorkQueueLogStyle>
      </Modal.Body>
    </Modal>
  );
};
export default WorkQueueModal;
